import React from "react"
import { makeStyles } from "@material-ui/core/styles/index"
import { Container, Box, Typography } from "@material-ui/core/index"

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(22, 7, 8),
    backgroundColor: "transparent",
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      padding: theme.spacing(14, 7, 8),
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1rem",
    borderLeft: "3px solid #565A56",
    width: "100%",
  },
  title: {
    color: "white",
    textTransform: "uppercase",
    align: "left",
    fontWeight: 300,
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "2.3rem",
    },
  },
  subheader: {
    color: "white",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "550px",
    fontWeight: 300,
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "1.5rem",
    },
    ["@media (max-width:1140px) and (min-width:780px)"]: {
      fontSize: "1.7rem",
    },
  },
  lightDecoLine: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1rem",
    borderLeft: "3px solid #ECECEC",
    width: "100%",
  },
}))

export default ({
  header,
  subheader,
  titleContainer,
  titleStyle,
  subHeaderStyle,
  lightDecoLine,
}) => {
  const classes = useStyles()
  return (
    <Container
      maxWidth={"xl"}
      component="main"
      className={[classes.heroContent, titleContainer]}
    >
      <div
        className={
          lightDecoLine ? `${classes.lightDecoLine}` : `${classes.container}`
        }
      >
        <Typography
          component="h1"
          variant="h2"
          align="left"
          className={[classes.title, titleStyle]}
        >
          {header}
        </Typography>
      </div>
      <Typography
        component="h2"
        variant="h4"
        align="left"
        className={[classes.subheader, subHeaderStyle]}
        // className={`${classes.subheader} ${subHeaderStyle || ""}`}
      >
        {subheader}
      </Typography>
    </Container>
  )
}
